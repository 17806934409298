import React from 'react';
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";

const Navbar = () => {
    return (
        <>
            <header className="header navbar-area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="nav-inner">
                                <nav className="navbar navbar-expand-lg">
                                    <Link to className="navbar-brand" href="/">
                                        <img src="assets/images/logo/logo.svg" alt="Logo"/>
                                    </Link>
                                     <button className="navbar-toggler mobile-menu-btn" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                            aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="toggler-icon"></span>
                                        <span className="toggler-icon"></span>
                                        <span className="toggler-icon"></span>
                                    </button>
                                    <div className="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                                        <ul id="nav" className="navbar-nav ms-auto">
                                            <li className="nav-item">
                                                <NavLink exact to="/" >
                                                    Home
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink exact to="/services">
                                                    Services
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink exact  to="/portfolio" >
                                                    Portfolio
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink exact  to="/contact">
                                                    Contact
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="button add-list-button">
                                        <NavLink to={"/contact"} className="btn">
                                            Contact Us
                                        </NavLink>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Navbar;