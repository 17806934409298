import React from 'react';

import { BrowserRouter as Router, Routes,Route } from "react-router-dom";
import Navbar from "./components/Navbar"
import Footer from "./components/Footer"
import Home from "./pages/Home"
import Services from "./pages/Services"
import Portfolio from "./pages/Portfolio"
import Contact from "./pages/Contact"
import Error from "./pages/Error"

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path='' exact element={<Home />} />
        <Route path='/services' exact element={<Services/>} />
        <Route path='/portfolio' exact element={<Portfolio/>} />
        <Route path='/contact' exact element={<Contact/>} />
        <Route path='*' element={<Error/>} />
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;