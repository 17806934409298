import React from 'react';
import { Link } from "react-router-dom";
import { useEffect } from 'react';

const Error = () =>{
  useEffect(() => {
    const script = document.createElement('script');
    const script2 = document.createElement('script');
  
    script.src = "/assets/js/error.js";
    script.async = true;
  
    document.body.appendChild(script);
  
    return () => {
      document.body.removeChild(script);
    }
}, []);
  return (
    <div>
      <div className="error-area">
          <div className="d-table">
              <div className="d-table-cell">
                  <div className="container">
                      <div className="error-content">
                          <h1>404</h1>
                          <h2>Oops! Page Not Found!</h2>
                          <p>The page you are looking for does not exist. It might have been moved or deleted.</p>
                          <div className="button">
                              <a onClick={() => {window.location.href="/"}} exect className="btn">Back to Home</a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
  );
}
export default Error;