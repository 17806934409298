import React from 'react';
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <>
            <footer className="footer">
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5 col-md-5 col-12">

                                <div className="single-footer f-about">
                                    <div className="logo">
                                        <Link to="/">
                                            <img src="assets/images/logo/white-logo.svg" alt="Alfath Tech"/>
                                        </Link>
                                    </div>
                                    <p>We are an agile software development company that uses the latest technology in the
                                        development process
                                    </p>
                                    <p>Jl. Mertojoyo Selatan Blk. C, Merjosari, Kec. Lowokwaru, Kota Malang, Jawa Timur 65144
                                    </p>
                                    <ul className="social">
                                        <li><a href="https://www.facebook.com/alfathtech" target="_blank"><i
                                                    className="lni lni-facebook-filled"></i></a></li>
                                        <li><a href="https://www.instagram.com/alfathtech.co.id/" target="_blank"><i
                                                    className="lni lni-instagram"></i></a></li>
                                        <li><a href="https://wa.me/6282244637000" target="_blank"><i
                                                    className="lni lni-whatsapp"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-7 col-12">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-12">

                                        <div className="single-footer f-link">
                                            <h3>Contact Us</h3>
                                            <ul>
                                                <li><a href="mailto:business@alfathtech.co.id">Email :
                                                        business@alfathtech.co.id</a></li>
                                                <li><a href="tel:+6282244637000">Phone : +62 822 4463 7000</a></li>
                                                <li><a href="https://wa.me/6282244637000" target="_blank">Whatsapp: +62 822 4463
                                                        7000</a></li>
                                            </ul>
                                        </div>

                                    </div>
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="single-footer f-link">
                                            <h3>Pages</h3>
                                            <ul>
                                                <li><Link to="/">Home</Link></li>
                                                <li><Link to="services.html">Services</Link></li>
                                                <li><Link to="portfolio.html">Portfolio</Link></li>
                                                <li><Link to="contact.html">Contact</Link></li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;