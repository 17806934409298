import React from 'react';
import { Link } from "react-router-dom";
import { useEffect } from 'react';

const Home = () =>{
    useEffect(() => {
        const script = document.createElement('script');
        const script2 = document.createElement('script');
      
        script.src = "/assets/js/home.js";
        script.async = true;
      
        script2.src = "/assets/js/main.js";
        script2.async = true;
      
        document.body.appendChild(script);
        document.body.appendChild(script2);
      
        return () => {
          document.body.removeChild(script);
          document.body.removeChild(script2);
        }
  }, []);

  return (
    <div>
        <section className="hero-area">
            <div className="main__circle1 bg-blur-150"></div>
            <div className="main__circle2 bg-blur-100"></div>
            <div className="main__circle3 bg-blur-150"></div>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-8 offset-lg-2 col-md-12 col-12">
                        <div className="hero-content">
                            <h1 className="wow fadeInUp" data-wow-delay=".2s">Building Business, <span>Digital Products & Experience</span>
                            </h1>
                            <p className="wow fadeInUp" data-wow-delay=".4s">We are an agile software development company that uses the latest technology in the development process</p>
                            <div className="button wow fadeInUp" data-wow-delay=".6s">
                                <a href="#services" className="btn">Know More <i className="lni lni-arrow-down"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="services" className="features section">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title">
                            <h3 className="wow zoomIn" data-wow-delay=".2s">Services</h3>
                            <h2 className="wow fadeInUp" data-wow-delay=".4s">We Are Creative Person <span>And Innovators</span></h2>
                            <p className="wow fadeInUp" data-wow-delay=".6s">There are several types of services that we offer</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="single-feature wow fadeInUp" data-wow-delay=".2s">
                            <i className="lni lni-cloud-upload"></i>
                            <h3>Product Design (UI/UX)</h3>
                            <p>We will make your product design and application design according to your wishes and business needs</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="single-feature wow fadeInUp" data-wow-delay=".4s">
                            <i className="lni lni-lock"></i>
                            <h3>Website Development</h3>
                            <p>We develop web applications on various platforms so that they are more effective, efficient and accessible</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="single-feature wow fadeInUp" data-wow-delay=".6s">
                            <i className="lni lni-reload"></i>
                            <h3>Mobile Apps Development</h3>
                            <p>We develop mobile applications on the Android or iOS platform that can be opened on your smartphone</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="services section">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title">
                            <h3 className="wow zoomIn" data-wow-delay=".2s">FEATURES</h3>
                            <h2 className="wow fadeInUp" data-wow-delay=".4s">Why should choose us?</h2>
                            <p className="wow fadeInUp" data-wow-delay=".6s">We have several services and advantages compared to other companies</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6 col-12">
                        <div className="single-service">
                            <i className="lni lni-certificate"></i>
                            <h3>Legal</h3>
                            <p>We have legality from government as a software development company
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12">
                        <div className="single-service">
                            <i className="lni lni-thumbs-up"></i>
                            <h3>Great Experience</h3>
                            <p>Successfully worked on many projects over the years with maximum results
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12">
                        <div className="single-service">
                            <i className="lni lni-users"></i>
                            <h3>Professional Team</h3>
                            <p>With a professional team we are able to meet customer needs according to their wishes
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12">
                        <div className="single-service">
                            <i className="lni lni-target"></i>
                            <h3>On Target</h3>
                            <p>With the latest technology we always provide appropriate, effective and efficient solutions for IT problems
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12">
                        <div className="single-service">
                            <i className="lni lni-shield"></i>
                            <h3>After Sales</h3>
                            <p>There is always a guarantee and assistance for every project we complete
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12">
                        <div className="single-service">
                            <i className="lni lni-headphone-alt"></i>
                            <h3>Responsive</h3>
                            <p>Always keep in touch by answering your questions via Whatsapp, Phone or Email.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12">
                        <div className="single-service">
                            <i className="lni lni-handshake"></i>
                            <h3>Full Support</h3>
                            <p>We provide full support from the planning stage to maintenance.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12">
                        <div className="single-service">
                            <i className="lni lni-laptop"></i>
                            <h3>Free Domain <span className="text-warning">*</span></h3>
                            <p>We give free domains to customers who have collaborated. *Terms and conditions apply.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="portfolio-section section">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title">
                            <h3 className="wow zoomIn" data-wow-delay=".2s">Portfolio</h3>
                            <h2 className="wow fadeInUp" data-wow-delay=".4s">Our Last Projects</h2>
                            <p className="wow fadeInUp" data-wow-delay=".6s">We have helped complete various projects from various types of products. Come check out our portfolio!</p>
                        </div>
                    </div>
                </div>

                <div className="portfolio-carousel">
                    <div className="p-2 grid-item webapp">
                        <div className="portfolio-item-wrapper">
                            <div className="portfolio-img">
                                <img src="assets/images/portfolio/syariahrooms.jpg" alt="Syariahrooms"/>
                            </div>
                            <div className="portfolio-overlay">
                                <div className="pf-content">
                                    <span className="category">Web App</span>
                                    <h4>Syariahrooms</h4>
                                    <p>Syariahrooms is a sharia lodging booking website that has spread in several cities</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-2 grid-item webapp">
                        <div className="portfolio-item-wrapper">
                            <div className="portfolio-img">
                                <img src="assets/images/portfolio/kelola.jpg" alt="Kelola.net"/>
                            </div>
                            <div className="portfolio-overlay">
                                <div className="pf-content">
                                    <span className="category">Web App</span>
                                    <h4>Kelola.net</h4>
                                    <p>Kelola.net is a practical website that is equipped with advanced features to help you develop your clinic</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-2 grid-item webapp">
                        <div className="portfolio-item-wrapper">
                            <div className="portfolio-img">
                                <img src="assets/images/portfolio/siber.jpg" alt="Sistemberkah"/>
                            </div>
                            <div className="portfolio-overlay">
                                <div className="pf-content">
                                    <span className="category">Web App</span>
                                    <h4>Sistemberkah</h4>
                                    <p>Sistemberkah is a fast approval (SLA Real Time) used by Bank Syariah Indonesia (BSI) in approving financing applications</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-2 grid-item webapp">
                        <div className="portfolio-item-wrapper">
                            <div className="portfolio-img">
                                <img src="assets/images/portfolio/siber-telemarketing.jpg" alt="Sistemberkah Telemarketing"/>
                            </div>
                            <div className="portfolio-overlay">
                                <div className="pf-content">
                                    <span className="category">Web App</span>
                                    <h4>Sistemberkah - Telemarketing</h4>
                                    <p>Sistemberkah - Telemarketing is a marketing system by offering products or services to customers by telephone</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-2 grid-item webapp">
                        <div className="portfolio-item-wrapper">
                            <div className="portfolio-img">
                                <img src="assets/images/portfolio/ekaakarjati.jpg" alt="Eka Akar Jati"/>
                            </div>
                            <div className="portfolio-overlay">
                                <div className="pf-content">
                                    <span className="category">Web App</span>
                                    <h4>Ekaakarjati</h4>
                                    <p>Ekaakarjati is a company profile website as the identity of PT Eka Akar Jati</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-2 grid-item webapp">
                        <div className="portfolio-item-wrapper">
                            <div className="portfolio-img">
                                <img src="assets/images/portfolio/karir-ekaakarjati.jpg" alt="Karir Ekaakarjati"/>
                            </div>
                            <div className="portfolio-overlay">
                                <div className="pf-content">
                                    <span className="category">Web App</span>
                                    <h4>Ekaakarjati - Karir</h4>
                                    <p>Ekaakarjati is a recruitment website provided by PT Eka Akar Jati which features job application, automatic selection and online test features.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-2 grid-item webapp">
                        <div className="portfolio-item-wrapper">
                            <div className="portfolio-img">
                                <img src="assets/images/portfolio/idelearning.jpg" alt="IDE Learning"/>
                            </div>
                            <div className="portfolio-overlay">
                                <div className="pf-content">
                                    <span className="category">Web App</span>
                                    <h4>IDE Learning</h4>
                                    <p>IDE Leraning is a company profile website as the identity of 
                                        Indonesia's Leading Digital Soft Skill Learning </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-2 grid-item webapp">
                        <div className="portfolio-item-wrapper">
                            <div className="portfolio-img">
                                <img src="assets/images/portfolio/HEI-Web.jpg" alt="Web Halal Export Indonesia"/>
                            </div>
                            <div className="portfolio-overlay">
                                <div className="pf-content">
                                    <span className="category">Web App</span>
                                    <h4>HEI - Web</h4>
                                    <p>HEI is an export marketplace website that provides export of Indonesian products. Equipped with merchant and e-learning features to learn about export and import</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-2 grid-item mobileapp">
                        <div className="portfolio-item-wrapper">
                            <div className="portfolio-img">
                                <img src="assets/images/portfolio/HEI-Apps.jpg" alt="Apps Halal Export Indonesia"/>
                            </div>
                            <div className="portfolio-overlay">
                                <div className="pf-content">
                                    <span className="category">Mobile App</span>
                                    <h4>HEI - Apps</h4>
                                    <p>HEI is an export marketplace apps (Android/iOS) that provides export of Indonesian products. Equipped with merchant and e-learning features to learn about export and import</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-2 grid-item mobileapp">
                        <div className="portfolio-item-wrapper">
                            <div className="portfolio-img">
                                <img src="assets/images/portfolio/aset.jpg" alt="Sistemberkah Telemarketing"/>
                            </div>
                            <div className="portfolio-overlay">
                                <div className="pf-content">
                                    <span className="category">Mobile App</span>
                                    <h4>Aset.id</h4>
                                    <p>Aset.id is the largest mobile-based property marketplace application (Android and iOS) that provides property sales and purchases.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-2 grid-item webapp">
                        <div className="portfolio-item-wrapper">
                            <div className="portfolio-img">
                                <img src="assets/images/portfolio/hoola.jpg" alt="IDE Learning"/>
                            </div>
                            <div className="portfolio-overlay">
                                <div className="pf-content">
                                    <span className="category">Web App</span>
                                    <h4>Hoola.id</h4>
                                    <p>Hoola.id is a company profile website as the identity of 
                                        Hoola Drinks </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="call-action">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-8 col-12">
                        <div className="text">
                            <h2>Consult your problem with us, <span>and we will provide the best solution for you</span>
                            </h2>
                        </div>
                    </div>
                    <div className="col-lg-4 col-12">
                        <div className="button">
                            <Link exact to="/contact" className="btn">Contact Us
                          </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  );
}
export default Home;