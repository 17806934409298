import React from 'react';

const Contact = () =>{
  return (
    <div>
        <section id="contact-us" className="contact-us section first-section">
          <div className="container">
              <div className="contact-head wow fadeInUp" data-wow-delay=".4s">
                  <div className="row">
                      <div className="col-12">
                          <div className="section-title">
                              <h3 className="wow zoomIn" data-wow-delay=".2s">Contact</h3>
                              <h2 className="wow fadeInUp" data-wow-delay=".4s">Communicate with us</h2>
                              <p className="wow fadeInUp" data-wow-delay=".6s">We provide an initial consultation without any conditions. We will help in finding the needs as your business solution</p>
                          </div>
                      </div>
                  </div>
                  <div className="contact-info">
                      <div className="row">
                          <div className="col-lg-4 col-md-12 col-12">
                              <div className="single-info">
                                  <i className="lni lni-map"></i>
                                  <h3>Address</h3>
                                  <ul>
                                      <li>Jl. Mertojoyo Selatan Blk. C, Merjosari, Kec. Lowokwaru, Kota Malang, Jawa Timur 65144</li>
                                  </ul>
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-12 col-12">
                              <div className="single-info">
                                  <i className="lni lni-phone"></i>
                                  <h3>Call us on</h3>
                                  <ul>
                                      <li><a href="tel:+6282244637000">+62 822 4463 7000 (Phone)</a></li>
                                      <li><a href="https://wa.me/6282244637000">+62 822 4463 7000 (Whatsapp)</a></li>
                                  </ul>
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-12 col-12">
                              <div className="single-info">
                                  <i className="lni lni-envelope"></i>
                                  <h3>Mail at</h3>
                                  <ul>
                                      <li><a href="mailto:business@alfathtech.co.id">business@alfathtech.co.id</a></li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </section>

        <section className="about-us section">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="content-left wow fadeInLeft" data-wow-delay=".3s">
                            <img src="assets/images/work.svg" alt="Work Process"/>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="content-right wow fadeInRight" data-wow-delay=".5s">
                            <h2>Work Process</h2>
                            <p>In the process, we prioritize communication with customers. With good communication, we will produce quality products.</p>
                            <div className="row">
                                <div className="col-lg-6 col-12">
                                    <ul className="list">
                                        <li><span className="number">1</span> Contact Us </li>
                                        <li><span className="number">2</span> Consultation </li>
                                        <li><span className="number">3</span> Contract Agreement </li>
                                        <li><span className="number">4</span> System Spesification </li>
                                        <li><span className="number">5</span> UI/UX Design </li>
                                    </ul>
                                </div>
                                <div className="col-lg-6 col-12">
                                    <ul className="list">
                                        <li><span className="number">6</span> Development </li>
                                        <li><span className="number">7</span> Testing </li>
                                        <li><span className="number">8</span> Publication/Deploy </li>
                                        <li><span className="number">9</span> Maintenance </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  );
}
export default Contact;