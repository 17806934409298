import React from 'react';
import { Link } from "react-router-dom";

const Services = () =>{
  return (
    <div>
        <section id="services" className="features section first-section">
          <div className="container">
              <div className="row">
                  <div className="col-12">
                      <div className="section-title">
                          <h3 className="wow zoomIn" data-wow-delay=".2s">Services</h3>
                          <h2 className="wow fadeInUp" data-wow-delay=".4s">We Are Creative Person <span>And Innovators</span></h2>
                          <p className="wow fadeInUp" data-wow-delay=".6s">There are several types of services that we offer</p>
                      </div>
                  </div>
              </div>
              <div className="row">
                  <div className="col-lg-4 col-md-6 col-12">
                      <div className="single-feature wow fadeInUp" data-wow-delay=".2s">
                          <i className="lni lni-cloud-upload"></i>
                          <h3>Product Design (UI/UX)</h3>
                          <p>We will make your product design and application design according to your wishes and business needs</p>
                      </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12">
                      <div className="single-feature wow fadeInUp" data-wow-delay=".4s">
                          <i className="lni lni-lock"></i>
                          <h3>Website Development</h3>
                          <p>We develop web applications on various platforms so that they are more effective, efficient and accessible</p>
                      </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12">
                      <div className="single-feature wow fadeInUp" data-wow-delay=".6s">
                          <i className="lni lni-reload"></i>
                          <h3>Mobile Apps Development</h3>
                          <p>We develop mobile applications on the Android or iOS platform that can be opened on your smartphone</p>
                      </div>
                  </div>
              </div>
          </div>
         </section>

        <section className="services section">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-title">
                            <h3 className="wow zoomIn" data-wow-delay=".2s">FEATURES</h3>
                            <h2 className="wow fadeInUp" data-wow-delay=".4s">Why should choose us?</h2>
                            <p className="wow fadeInUp" data-wow-delay=".6s">We have several services and advantages compared to other companies</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6 col-12">
                        <div className="single-service">
                            <i className="lni lni-certificate"></i>
                            <h3>Legal</h3>
                            <p>We have legality from government as a software development company
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12">
                        <div className="single-service">
                            <i className="lni lni-thumbs-up"></i>
                            <h3>Great Experience</h3>
                            <p>Successfully worked on many projects over the years with maximum results
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12">
                        <div className="single-service">
                            <i className="lni lni-users"></i>
                            <h3>Professional Team</h3>
                            <p>With a professional team we are able to meet customer needs according to their wishes
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12">
                        <div className="single-service">
                            <i className="lni lni-target"></i>
                            <h3>On Target</h3>
                            <p>With the latest technology we always provide appropriate, effective and efficient solutions for IT problems
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12">
                        <div className="single-service">
                            <i className="lni lni-shield"></i>
                            <h3>After Sales</h3>
                            <p>There is always a guarantee and assistance for every project we complete
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12">
                        <div className="single-service">
                            <i className="lni lni-headphone-alt"></i>
                            <h3>Responsive</h3>
                            <p>Always keep in touch by answering your questions via Whatsapp, Phone or Email.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12">
                        <div className="single-service">
                            <i className="lni lni-handshake"></i>
                            <h3>Full Support</h3>
                            <p>We provide full support from the planning stage to maintenance.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12">
                        <div className="single-service">
                            <i className="lni lni-laptop"></i>
                            <h3>Free Domain <span className="text-warning">*</span></h3>
                            <p>We give free domains to customers who have collaborated. *Terms and conditions apply.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="about-us section">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="content-left wow fadeInLeft" data-wow-delay=".3s">
                            <img src="assets/images/work.svg" alt="Work Process"/>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="content-right wow fadeInRight" data-wow-delay=".5s">
                            <h2>Work Process</h2>
                            <p>In the process, we prioritize communication with customers. With good communication, we will produce quality products.</p>
                            <div className="row">
                                <div className="col-lg-6 col-12">
                                    <ul className="list">
                                        <li><span className="number">1</span> Contact Us </li>
                                        <li><span className="number">2</span> Consultation </li>
                                        <li><span className="number">3</span> Contract Agreement </li>
                                        <li><span className="number">4</span> System Spesification </li>
                                        <li><span className="number">5</span> UI/UX Design </li>
                                    </ul>
                                </div>
                                <div className="col-lg-6 col-12">
                                    <ul className="list">
                                        <li><span className="number">6</span> Development </li>
                                        <li><span className="number">7</span> Testing </li>
                                        <li><span className="number">8</span> Publication/Deploy </li>
                                        <li><span className="number">9</span> Maintenance </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="call-action">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-8 col-12">
                        <div className="text">
                            <h2>Consult your problem with us, <span>and we will provide the best solution for you</span>
                            </h2>
                        </div>
                    </div>
                    <div className="col-lg-4 col-12">
                        <div className="button">
                            <Link to="/contact" className="btn">Contact Us
                          </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  );
}
export default Services;